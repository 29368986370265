import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import HeaderIndex from "../components/header-index"
import BodyWrapper from "../components/body-wrapper"

const SecondPage = () => (
  <Layout>
    <SEO title="Datenschutz" />

    <HeaderIndex rootLink={true} />
    <BodyWrapper>
      <h1>Datenschutz</h1>

      <h2>Allgemeines</h2>
      <p>
        Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten
        sehr ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und
        entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser
        Datenschutzerklärung. Wenn Sie diese Website benutzen, werden
        verschiedene personenbezogene Daten erhoben. Personenbezogene Daten sind
        Daten, mit denen Sie persönlich identifiziert werden können. Die
        vorliegende Datenschutzerklärung erläutert, welche Daten wir erheben und
        wofür wir sie nutzen. Sie erläutert auch, wie und zu welchem Zweck das
        geschieht.
      </p>
      <p>
        Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei
        der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein
        lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht
        möglich.
      </p>
      <p>
        Werden Daten von uns an einen Unterauftragsnehmer oder Dritten
        übermittelt, erfolgt dies auf geeigneter Rechtsgrundlagen gem. Art. 6
        DSGVO, z.B. zur Erfüllung unserer (vor)vertraglichen Pflichten, auf
        Grundlage Ihrer Einwilligung oder auf Grundlage unserer berechtigten
        Interessen, sowie auf Basis eines Vertrags zur Auftragsverarbeitung gem.
        Art 28 DSGVO. Bei der Übermittlung in Drittländer erfolgt die
        Verarbeitung ferner auf Grundlage geeigneter Garantien gem. Art. 44 ff.
        DSGVO, wie einem Angemessenheitsbeschluss der EU-Kommission zum
        Datenschutzniveau eines Landes (inkl. des EU-US Privacy Shield) oder
        nach Abschluss offiziell anerkannter spezieller Verträge mit unseren
        Unterauftragnehmern (sogenannte „Standardvertragsklauseln“).
      </p>
      <h3>Hinweis zur verantwortlichen Stelle</h3>
      <p>
        Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website
        ist:
      </p>
      <p>
        Nicole Behnke
        <br />
        Landsberger Allee 157
        <br />
        10369 Berlin
      </p>
      <p>
        E-Mail: <a href="mailto:hallo@nicolebehnke.de">hallo@nicolebehnke.de</a>
      </p>
      <h2>Betroffenenrechte</h2>
      <h3>Widerruf Ihrer Einwilligung zur Datenverarbeitung</h3>
      <p>
        Einige Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen
        Einwilligung möglich. Sie können eine bereits erteilte Einwilligung gem.
        Art. 7 Abs. 3 DSGVO mit Wirkung für die Zukunft jederzeit widerrufen.
        Dazu reicht eine formlose Mitteilung per E-Mail an uns. Die
        Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitung bleibt
        vom Widerruf unberührt.
      </p>
      <h3>Widerspruch gegen die Verarbeitung Ihrer Daten</h3>
      <p>
        Sie können der Verarbeitung Ihrer Daten, die aufgrund von Art. 6 Abs. 1
        lit. e oder f DSGVO erfolgt, gem. Art. 21 DSGVO jederzeit für die
        Zukunft widersprechen. Der Widerspruch kann insbesondere gegen die
        Verarbeitung für Zwecke der Direktwerbung erfolgen.
      </p>
      <h3>Beschwerderecht bei einer Aufsichtsbehörde</h3>
      <p>
        Sie haben gem. Art. 77 DSGVO das Recht auf Beschwerde bei einer
        Aufsichtsbehörde, insbesondere in dem Mitgliedstaat Ihres gewöhnlichen
        Aufenthaltsorts, Ihres Arbeitsplatzes oder des Orts des mutmaßlichen
        Verstoßes, wenn Sie der Ansicht sind, dass die Verarbeitung der Sie
        betreffenden personenbezogenen Daten gegen die DSGVO verstößt.
      </p>
      <h3>Auskunft, Sperrung, Löschung</h3>
      <p>
        Sie haben im Rahmen der gem. Art. 15-17 DSGVO jederzeit das Recht auf
        unentgeltliche Auskunft über Ihre gespeicherten personenbezogenen Daten,
        deren Herkunft und Empfänger und den Zweck der Datenverarbeitung und
        ggf. ein Recht auf Berichtigung, Sperrung oder Löschung dieser Daten.
        Hierzu sowie zu weiteren Fragen zum Thema personenbezogene Daten können
        Sie sich jederzeit unter der im Impressum angegebenen Adresse an uns
        wenden.
      </p>
      <h3>Recht auf Datenübertragbarkeit</h3>
      <p>
        Sie haben gem. Art. 20 DSGVO das Recht, Daten, die wir auf Grundlage
        Ihrer Einwilligung oder in Erfüllung eines Vertrags automatisiert
        verarbeiten, an sich oder an einen Dritten in einem gängigen,
        maschinenlesbaren Format aushändigen zu lassen. Sofern Sie die direkte
        Übertragung der Daten an einen anderen Verantwortlichen verlangen,
        erfolgt dies nur, soweit es technisch machbar ist.
      </p>

      <h2>Datenerfassung und -übermittlung auf unserer Webseite</h2>

      <h3>Nutzung unserer Webseite</h3>
      <p>
        Um unsere Internetseiten bereitzustellen, den reibungslosen Betrieb zu
        gewährleisten und das Nutzungserlebnis für die Nutzer zu optimieren,
        verarbeiten wir bzw. unser Hostinganbieter Inhaltsdaten, Nutzungsdaten,
        Meta- und Kommunikationsdaten von Interessenten und Besuchern dieser
        Seiten auf Grundlage unseres berechtigten Interesses gem. Art. 6 Abs. 1
        lit. f DSGVO. Ohne Verarbeitung dieser Daten können Sie die Webseite
        nicht aufrufen.
      </p>
      <p>
        Bei jedem Zugriff auf die Webseite erhebt und speichert der Provider der
        Seiten automatisch Informationen in sogenannten Server-Log-Dateien, die
        Ihr Browser automatisch an uns übermittelt. Dies sind:
      </p>
      <ul>
        <li>Browsertyp und Browserversion</li>
        <li>verwendetes Betriebssystem</li>
        <li>Referrer URL</li>
        <li>Hostname des zugreifenden Rechners</li>
        <li>Uhrzeit der Serveranfrage</li>
        <li>IP-Adresse</li>
      </ul>
      <p>
        Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht
        vorgenommen. Die Löschung dieser Daten erfolgt, sobald sie nicht mehr
        benötigt werden, spätestens aber nach <strong>2 Wochen</strong>.
      </p>

      <h4>Cookies</h4>
      <p>
        Die Internetseiten verwenden teilweise sogenannte Cookies. Cookies
        richten auf Ihrem Rechner keinen Schaden an und enthalten keine Viren.
        Cookies dienen dazu, unser Angebot nutzerfreundlicher, effektiver und
        sicherer zu machen. Cookies sind kleine Textdateien, die auf Ihrem
        Rechner abgelegt werden und die Ihr Browser speichert.
      </p>
      <p>
        Die meisten der von uns verwendeten Cookies sind sogenannte
        “Session-Cookies”. Sie werden nach Ende Ihres Besuchs automatisch
        gelöscht. Andere Cookies bleiben auf Ihrem Endgerät gespeichert bis Sie
        diese löschen. Diese Cookies ermöglichen es uns, Ihren Browser beim
        nächsten Besuch wiederzuerkennen.
      </p>
      <p>
        Sie können Ihren Browser so einstellen, dass Sie über das Setzen von
        Cookies informiert werden und Cookies nur im Einzelfall erlauben, die
        Annahme von Cookies für bestimmte Fälle oder generell ausschließen sowie
        das automatische Löschen der Cookies beim Schließen des Browser
        aktivieren. Bei der Deaktivierung von Cookies kann die Funktionalität
        dieser Website eingeschränkt sein.
      </p>
      <p>
        Cookies, die zur Durchführung des elektronischen Kommunikationsvorgangs
        oder zur Bereitstellung bestimmter Funktionen (z.B. Warenkorbfunktion)
        erforderlich sind, werden auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO
        gespeichert. Der Websitebetreiber hat ein berechtigtes Interesse an der
        Speicherung von Cookies zur technisch fehlerfreien und optimierten
        Bereitstellung seiner Dienste. Wenn Sie keine Cookies akzeptieren oder
        bereits gesetzte Cookies löschen, kann dies zu Funktionseinschränkungen
        der Webseite führen.
      </p>

      <h3>Kontaktaufnahme per E-Mail</h3>
      <p>
        Wenn Sie uns per E-Mail Anfragen zukommen lassen, werden
        personenbezogene Daten erhoben, mindestens Ihre E-Mailadresse. Welche
        Daten darüberhinaus automatisch übermittelt werden (z.B. vollständiger
        Name), hängt von den Einstellungen Ihres E-Mail-Programms ab. Welche
        Daten Sie im Inhalt oder als Anhang der E-Mail übermitteln möchten,
        liegt in Ihrem Ermessen.
      </p>
      <p>
        Rechtsgrundlage für die Verarbeitung der Daten ist unser berechtigtes
        Interesse an der Beantwortung Ihres Anliegens gemäß Art. 6 Abs. 1 lit. f
        DSGVO. Bei Angebotsanfragen u.ä. geschieht die Verarbeitung zusätzlich
        auf der Rechtsgrundlage Art. 6 Abs. 1 lit. b DSGVO, wonach die
        Verarbeitung rechtmäßig ist, wenn sie für die Erfüllung eines Vertrags,
        dessen Vertragspartei die betroffene Person ist, oder zur Durchführung
        vorvertraglicher Maßnahmen erforderlich ist. Ohne diese Daten können wir
        Ihre Anfrage nicht erhalten bzw. bearbeiten.
      </p>
      <p>
        Die von Ihnen übermittelten Daten verbleiben bei uns, bis der Zweck für
        die Datenspeicherung entfällt (z.B. nach abgeschlossener Bearbeitung
        Ihrer Anfrage). Zwingende gesetzliche Bestimmungen – insbesondere
        Aufbewahrungsfristen – bleiben unberührt.
      </p>

      <h3>Externe Dienste und Services</h3>

      <h4>Google Web Fonts</h4>
      <p>
        Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten
        sogenannte Web Fonts, die von Google LLC (1600 Amphitheatre Pkwy,
        Mountain View, California 94043, USA) bereitgestellt werden. Beim Aufruf
        einer Seite lädt Ihr Browser die benötigten Web Fonts in Ihren
        Browsercache, um Texte und Schriftarten korrekt anzuzeigen.
      </p>
      <p>
        Zu diesem Zweck muss der von Ihnen verwendete Browser Verbindung zu den
        Servern von Google aufnehmen. Hierdurch erlangt Google Kenntnis darüber,
        dass über Ihre IP-Adresse unsere Website aufgerufen wurde. Die Nutzung
        von Google Web Fonts erfolgt im Interesse einer einheitlichen und
        ansprechenden Darstellung unserer Onlineangebote. Dies stellt ein
        berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar. Wenn
        Ihr Browser Web Fonts nicht unterstützt, wird eine Standardschrift von
        Ihrem Computer genutzt.
      </p>
      <p>
        Weitere Informationen zu Google Web Fonts finden Sie unter{" "}
        <a href="https://developers.google.com/fonts/faq">
          https://developers.google.com/fonts/faq
        </a>
         und in der Datenschutzerklärung von Google:{" "}
        <a href="https://www.google.com/policies/privacy/">
          https://www.google.com/policies/privacy/
        </a>
        .
      </p>

      <h4>Einbindung von Videos via YouTube</h4>
      <p>
        Unsere Website nutzt Plugins der von Google betriebenen Seite YouTube.
        Betreiber der Seiten ist die YouTube, LLC (901 Cherry Ave., San Bruno,
        CA 94066, USA) ein Tochterunternehmen der Google LLC (Amphitheatre
        Parkway, Mountain View, CA 94043, USA).
      </p>
      <p>
        Wir verwenden bei der Einbettung den erweiterten Datenschutzmodus.
        Dadurch wird die Verbindung zu den Servern von YouTube erst hergestellt,
        wenn Sie sich das Video ansehen. Wenn Sie in Ihrem YouTube-Account
        eingeloggt sind, ermöglichen Sie YouTube, Ihr Surfverhalten direkt Ihrem
        persönlichen Profil zuzuordnen. Dies können Sie verhindern, indem Sie
        sich aus Ihrem YouTube-Account ausloggen.
      </p>
      <p>
        Die Nutzung von YouTube erfolgt im Interesse einer ansprechenden
        Darstellung unserer Online-Angebote. Dies stellt ein berechtigtes
        Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar. Ohne die
        Bereitstellung der Daten können Sie die Funktion nicht nutzen.
      </p>
      <p>
        Weitere Informationen zum Umgang mit Nutzerdaten finden Sie in der
        Datenschutzerklärung von YouTube unter:{" "}
        <a href="https://www.google.de/intl/de/policies/privacy">
          https://www.google.de/intl/de/policies/privacy
        </a>
        .
      </p>
    </BodyWrapper>
  </Layout>
)

export default SecondPage
